import styled from 'styled-components'

export const Section = styled.section`
  background: #373f49 0% 0% no-repeat padding-box;
  display: flex;
  align-content: center;
  min-height: 730px;

  @media (min-height: 876px) {
    min-height: calc(100vh - 146px);
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin: auto;
  padding: 2em 0;

  .our-awards-blue-line {
    border-top: 1px solid #147efb;
    margin-bottom: 6%;
  }

  #second-line {
    margin: 6% 0 0 0;
  }
  @media only screen and (min-width: 1440px) {
    display: grid;
    max-width: 70%;
  }
  @media only screen and (min-width: 1500px) {
    max-width: 70%;
  }
  @media only screen and (min-width: 1850px) {
    max-width: 60%;
  }
  @media only screen and (min-width: 2000px) {
    max-width: 55%;
  }
  
  .our-awards-cards {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1em 3em;
    justify-content: center;
  }
  
  .card {
    width: 230px;
    img {
      width: 100%;
    }

    @media screen and (max-width: 1100px) {
      width: 100%;
    }

    :hover {
      z-index: 10;
    }
  }
`

export const TextContainer = styled.div`
  color: #ffffff;
  text-align: center;

  img {
    width: 35px;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 3.2rem;
    line-height: 8rem;
    font-weight: 800;
    text-transform: capitalize;
    letter-spacing: 0;
    margin-bottom: 20px;

    @media screen and (min-width: 767px) {
      font-size: 5rem;
    }
    @media (max-width: 1024px) {
      text-align: center;
      padding-bottom: 2rem;
    }
    @media screen and (min-width: 1440px) {
      font-size: 5rem;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 1.8em;
    letter-spacing: 0;
    margin-bottom: 7%;

    @media screen and (min-width: 767px) {
      font-size: 2.4rem;
    }
    @media screen and (min-width: 1440px) {
      font-size: 1.6rem;
    }
  }
`

export const Image = styled.img`
  width: 90%;
  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  @media screen and (min-width: 767px) {
    width: 80%;
  }
`
