import styled from 'styled-components';

export const Section = styled.section`
  background: #ffffff 0% 0% no-repeat;
  background: linear-gradient(0deg, #b9c1cc -5%, rgba(255, 255, 255, 1) 9%);
  display: flex;
  align-content: center;
  min-height: 730px;

  @media (min-height: 876px) {
    min-height: calc(100vh - 146px);
  }
  
`;

export const Container = styled.div`
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  padding: 5em 0;
  max-width: 80%;
  margin: auto;

  @media only screen and (min-width: 767px) {
    padding: 7em 0;
  }
  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;
  }
  @media only screen and (min-width: 1440px) {
    padding: 15em 0;
  }
  @media only screen and (min-width: 1500px) {
    max-width: 70%;
  }
  @media only screen and (min-width: 1850px) {
    max-width: 60%;
  }
  @media only screen and (min-width: 2000px) {
    max-width: 55%;
  }
`;

export const ImageContainer = styled.div`
  background-image: url(${(props) => props.backgroundUrl});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 100%;

  @media only screen and (max-width: 767px) {
    background-image: none;
    height: auto;
    justify-content: center;
  }
`;

export const Image = styled.img`
  box-shadow: 20px 30px 30px rgba(185, 193, 204, 0.4);
  width: 75%;

  @media screen and (min-width: 830px) {
    width: 68%;
  }
  @media screen and (min-width: 900px) {
    width: 60%;
  }
  @media screen and (min-width: 1024px) {
    width: 75%;
  }
  @media screen and (min-width: 1440px) {
    width: 75%;
  }
`;

export const TextContainer = styled.div`
  text-align: center;

  /* @media screen and (min-width: 1025px) {
    padding-top: 35%;
  }
  @media screen and (min-width: 1440px) {
    padding-top: 25%;
  } */

  h1 {
    font-size: 3.2rem;
    line-height: 4rem;
    font-weight: 800;
    text-transform: capitalize;
    letter-spacing: 0;
    color: #147efb;

    @media screen and (min-width: 767px) {
      font-size: 5rem;
    }
    @media (max-width: 1024px) {
      text-align: center;
      padding-bottom: 2rem;
    }
    @media screen and (min-width: 1440px) {
      font-size: 5rem;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 1.8em;
    letter-spacing: 0;
    color: #788293;
    margin-top: 40px;

    @media (max-width: 1024px) {
      text-align: center;
      /* margin-bottom: 70px; */
    }
    @media screen and (min-width: 767px) {
      font-size: 2.4rem;
    }
    @media screen and (min-width: 1440px) {
      font-size: 1.6rem;
    }
  }
`;
