//  libraries
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

//  styles
import { Section, H1, P, Wrapper } from './mainSection.style';

export default (props) => {
  const data = useStaticQuery(graphql`
    {
      strapiOurStory {
        data {
          info: mainSection {
            title
            content
          }
        }
        backgroundMainSection {
          backgroundImage: publicURL
        }
      }
    }
  `);

  const {
    data: { info },
    backgroundMainSection: { backgroundImage }
  } = data.strapiOurStory;

  return (
    <Section backgroundUrl={backgroundImage}>
      <Wrapper>
        <H1>{info.title}</H1>
        <P>{info.content}</P>
      </Wrapper>
    </Section>
  );
};
