//  libraries
import React, { useMemo } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

//  styles
import { Section, Container, TextContainer, Image } from './ourAwards.style'

const OurAwards = () => {
  const dataResult = useStaticQuery(graphql`
    {
      strapiOurStory {
        data {
          ourAwards {
            content
            title
          }
        }
        OurAwardCards {
          alternativeText
          caption
          url
        }
        ourAwardsIcon {
          publicURL
        }
      }
    }
  `)

  const { ourAwards } = dataResult.strapiOurStory.data

  const { OurAwardCards, ourAwardsIcon } = dataResult.strapiOurStory

  const cards = useMemo(
    () =>
      OurAwardCards.map((card, key) =>
        card.caption !== '' ? (
          <Link
            key={key}
            href={card.caption}
            target="_blank"
            className="card"
            rel="noopener noreferrer"
          >
            <Image src={card.url} alt={card.alternativeText} />
          </Link>
        ) : (
          <section key={key} className="card">
            <Image src={card.url} alt={card.alternativeText} />
          </section>
        )
      ),
    [OurAwardCards]
  )

  return (
    <Section>
      <Container>
        <TextContainer>
          <Fade left>
            <img
              src={ourAwardsIcon.publicURL}
              alt="Freight brokerage - digital freight brokerage – digital freight management - Edge Logistics"
            />
            <h1>{ourAwards.title}</h1>
            <p>{ourAwards.content}</p>
          </Fade>
        </TextContainer>
        <div className="our-awards-blue-line" />
        <Fade bottom big cascade>
          <section className="our-awards-cards">{cards}</section>
        </Fade>
        <div className="our-awards-blue-line" id="second-line" />
      </Container>
    </Section>
  )
}

export default OurAwards
