import styled from 'styled-components';
import { moveInLeft } from 'styles/animation';

export const Section = styled.section`
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
  align-items: center;
  text-align: center;
  padding: 100px 0;
  
  display: flex;
  align-content: center;
  min-height: 730px;

  @media (min-height: 876px) {
    min-height: calc(100vh - 146px);
  }
`;

export const Wrapper = styled.div`
  max-width: 130rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const H1 = styled.h1`
  ${moveInLeft('.8s', 'ease-out')}
  font-weight: 800;
  letter-spacing: 0;
  text-transform: capitalize;
  line-height: 8rem;
  color: #ffffff;
  font-size: 3.7rem;

  @media screen and (min-width: 767px) {
    font-size: 5rem;
  }
  @media screen and (min-width: 1440px) {
    font-size: 4.5rem;
  }
`;

export const P = styled.p`
  ${moveInLeft('.8s', 'ease-out')}
  max-width: 100%;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0;
  color: #ffffff;
  padding:0 3rem;
  padding-top: 5rem;
  margin: 0;
  line-height: 4rem;


  @media screen and (min-width: 767px) {
    font-size: 2.5rem;
  }
  @media screen and (min-width: 1024px) {
    max-width: 100%;
    line-height: 5rem;
  }
  @media screen and (min-width: 1440px) {
    font-size: 2.2rem;
    max-width: 100%;
  }
`;
