//  libraries
import React from 'react'

//components
import SEO from '../components/seo'
import Layout from 'components/layout'

//  containers
import MainSection from '../containers/ourStoryPage/mainSectionOurStory/index'
import OurStory from '../containers/ourStoryPage/aboutUs/index'
import OurAwards from '../containers/ourStoryPage/ourAwards'
import LifeAtEdge from 'containers/JoinOurTeam/ThirdSection'
import Testimonials from 'containers/JoinOurTeam/FourthSection'

export default () => {
  return (
    <Layout>
      <SEO
        title="Freight Brokerage with Instant Capacity and Easy App | Edge Logistics"
        description="Freight brokerage company offering instant capacity for shippers. Learn about Edge Logistics. See a demo of our amazing freight management &amp; trucking app"
        keywords={["Freight brokerage company","digital freight brokerage company","freight management"]}
      />
      <MainSection />
      <OurStory />
      <OurAwards />
      <LifeAtEdge />
      <Testimonials />
    </Layout>
  )
}
