//  libraries
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';

//  styles
import {
  Section,
  Container,
  TextContainer,
} from './aboutUs.style';

export default () => {
  const data = useStaticQuery(graphql`
    {
      strapiOurStory {
        data {
          info: firstSection {
            content
            title
          }
        }
        aboutUs {
          AboutUsImage: publicURL
        }
        backgroundFirstSection {
          background: publicURL
        }
      }
    }
  `);

  const {
    data: { info },
  } = data.strapiOurStory;

  return (
    <Section>
      <Container>
        <TextContainer>
          <Fade left>
            <h1>{info.title}</h1>
            <p>{info.content}</p>
          </Fade>
        </TextContainer>
      </Container>
    </Section>
  );
};
